import { render, staticRenderFns } from "./_OverlayMessage.vue?vue&type=template&id=84b3e1be&scoped=true&"
import script from "./_OverlayMessage.vue?vue&type=script&lang=js&"
export * from "./_OverlayMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84b3e1be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VContainer,VFadeTransition,VOverlay})
