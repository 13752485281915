<template>
  <v-banner
    two-linee
  >
    <v-avatar
      slot="icon"
      color="primary"
      size="40"
    >
      <v-icon large color="accent">
        mdi-information-variant
      </v-icon>
    </v-avatar>
    <p>
      <slot></slot>
    </p>
  </v-banner>
</template>

<script>

export default {
  components: {
  },

  props: {
  },

  data() {
    return {
    };
  },

  computed: {
  },

  created() {
  }
};
</script>

<style scoped>
</style>
