<template>
  <v-container fluid
    v-if="openModal"
  >
    <v-fade-transition>
      <v-overlay
        :opacity="$attrs.opacity || 0.7"
        absolute
        z-index="5"
        color="accent"
      >
        <slot></slot>
      </v-overlay>
    </v-fade-transition>
  </v-container>
</template>

<script>
export default {
  inheritAttrs: false,

  components: {},

  data() {
    return {
      openModal: false,
    };
  },

  methods: {
    open() {
      this.openModal = true;
    },
  }
};
</script>

<style scoped>
</style>
