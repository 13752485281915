<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">売上台帳</h1>
        <banner-hint class="my-3">
          Comming Soon!
        </banner-hint>
      </v-col>
    </v-row>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { API_ENDPOINT } from "@/literals.js";
import BannerHint from "@/components/_BannerHint.vue";
import OverlayMessage from '@/components/_OverlayMessage.vue'

export default {
  components: {
    'banner-hint': BannerHint,
    'overlay-message': OverlayMessage,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      modalMessage: '',
    };
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    apiEndpoint() {
      return API_ENDPOINT + "/";
    },
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
  },

  mounted() {
    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
    }
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
  }
};
</script>

<!-- ************************************* -->
<!-- ************** スタイル ************** -->
<!-- ************************************* -->
<style scoped>

@media screen and (max-width: 800px) {
}
</style>
